import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import FilterComponent from '../Components/FilterComponent';
import { Link } from 'react-router-dom';

const SideBarMenuTwo = ({
  genres,
  ageCategories,
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  selectedConditions,
  onConditionSelect,
  toggleSidebar, 
}) => {
  const handleAllBooksClick = () => {
    onGenreSelect([]); 
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: "#3c3c3c" }}>
      <Container fluid style={{ backgroundColor: "#3c3c3c", padding: 0 }}>     
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: "#3c3c3c" }} />
        <Navbar.Collapse id="basic-navbar-nav" style={{ backgroundColor: "#fff", marginBottom: "8px" }}>
        <NavDropdown title="Filter" id="basic-nav-dropdown" style={{ color: "#fff",backgroundColor:"#3c3c3c"}}>
          <FilterComponent
            genres={genres}
            ageCategories={ageCategories}
            selectedAges={selectedAges}
            onAgeSelect={onAgeSelect}
            selectedGenres={selectedGenres}
            onGenreSelect={onGenreSelect}
            selectedConditions={selectedConditions}
            onConditionSelect={onConditionSelect}
            toggleSidebar={toggleSidebar} 
          />
        </NavDropdown>
          <Nav className="me-auto" style={{ backgroundColor: "#3c3c3c", width: "100%" }}>
            <Nav.Item style={{ marginLeft: "200px" }}></Nav.Item>
            <Nav.Item style={{ marginRight: "150px" }}>
              <Link to={`/view-more/book`} style={{ color: "#fff", textDecoration: "none" }} onClick={handleAllBooksClick}>
                AllBooks
              </Link>
            </Nav.Item>
            {genres.map((genre) => (
              <Nav.Item key={genre} style={{ marginRight: "150px" }}>
                <Link to={`/genre-based-books/${genre}`} style={{ color: "#fff", textDecoration: "none" }}>
                  {genre}
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SideBarMenuTwo;
