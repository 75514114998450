import React from 'react';
import { Form } from 'react-bootstrap';

const AgeCategory = ({ selectedAges, onSelect, ageCategories, toggleSidebar }) => {
  // Handle change event for checkboxes
  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onSelect((prev) => [...prev, value]);
    } else {
      onSelect((prev) => prev.filter((age) => age !== value));
    }
    if (toggleSidebar) {
      toggleSidebar(); 
    }
  };

  return (
    <Form>
      {ageCategories.map((age) => (
        <Form.Check
          key={age}
          type="checkbox"
          id={`age-${age}`}
          value={age}
          checked={selectedAges.includes(age)}
          onChange={handleChange}
          label={age}
        />
      ))}
    </Form>
  );
};

export default AgeCategory;
