import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { add } from "../Store/BorrowCart";
import { addBuy } from "../Store/BuyCart";

const Price = ({ lendingPricePerDay, sellingPrice, book }) => {
  const dispatch = useDispatch();
  const quantity = 1;

  return (
    <div style={{ textAlign: 'center', color: '#555' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2px 0' }}>
        <p style={{ fontSize: '1rem', margin: '0', display: 'flex', alignItems: 'center' }}>
          <strong>Borrow:</strong> ₹{lendingPricePerDay}<span>/day</span>
          <button 
            onClick={() => dispatch(add({ book, quantity }))}
            style={{ border: "none", backgroundColor: "white", marginLeft: "10px", display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </button>
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2px 0' }}>
        <p style={{ fontSize: '1rem', margin: '0', display: 'flex', alignItems: 'center' }}>
          <strong>Buy:</strong> ₹{sellingPrice}
          <button 
            onClick={() => dispatch(addBuy({ book, quantity }))}
            style={{ border: "none", backgroundColor: "white", marginLeft: "10px", display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </button>
        </p>
      </div>
    </div>
  );
};

export default Price;
