// src/App.jsx
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router,Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import ViewMorePage from "./Components/ViewMorePage";
import Contact from "./assets/Contact";
import { Cart } from "./Components/Cart";
import "./Scss/scssStyles.scss";
import GenreBasedBook from "./Components/GenreBasedBook";
import config from "./Config/config";
import Spinner from "./Spinner/Spinner";
import axiosInstance, { setupAxiosInterceptors } from "./axiosInstance";
import TermsConditions from "./policy/TermsConditions";
import PrivacyPolicy from "./policy/PrivacyPolicy";
import RefundPolicy from "./policy/ RefundPolicy";
import ReturnPolicy from "./policy/ReturnPolicy";
import ShippingPolicy from "./policy/Shipping Policy";
import AboutUs from "./assets/AboutUs";
import Error404 from "./ErrorPage/Error404";
import Error500 from "./ErrorPage/Error500"; 
import { ErrorContext, ErrorProvider } from "./Context/ErrorContext";





const App = () => {
  // State variables
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ageCategories, setAgeCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [staffBooks, setStaffBooks] = useState([]);
  const [basedOnYourViewBooks, setBasedOnYourViewBooks] = useState([]);
  const [axiosLoading, setAxiosLoading] = useState(false); 
  const [randomValue, setRandomValue] = useState(Math.floor(Math.random() * 10000)); 
  
  const location = useLocation();
  const { error, setError } = useContext(ErrorContext); // Consume the ErrorContext

  // Set up Axios interceptors for global loading and error management
  useEffect(() => {
    setupAxiosInterceptors(setAxiosLoading, setError);
  }, [setError]);

  // Generate a new random value whenever the location (path) changes
  useEffect(() => {
    const newRandomValue = Math.floor(Math.random() * 10000);
    setRandomValue(newRandomValue);
  }, [location]);

  // Fetch books and other metadata using Axios
  const fetchBooksData = async () => {
    try {
      const response = await axiosInstance.get(`${config.booksUrl}?v=${randomValue}`);
      const data = response.data;

      const booksArray = Object.keys(data).map((key) => ({
        isbn: data[key].in,
        title: data[key].te,
        sellingPrice: data[key].sp,
        lendingPricePerDay: data[key].lp,
        author: data[key].au,
        ageGroup: data[key].ag,
        genre: data[key].cy,
        condition: data[key].cn,
        imageUrl: `${config.imageUrlBase}${data[key].in}.jpg`,
      }));

      const ageCategoriesArray = [
        ...new Set(
          Object.keys(data)
            .map((key) => data[key].ag)
            .filter((age) => age !== null)
        ),
      ];
      setAgeCategories(ageCategoriesArray);

      const genreArray = [
        ...new Set(Object.keys(data).map((key) => data[key].cy)),
      ];
      setGenres(genreArray);

      setBooks(booksArray);
      setFilteredBooks(booksArray);
    } catch (error) {
      console.error("Error fetching the book data:", error);
      // 5xx errors are handled by Axios interceptor
    }
  };

  // Fetch staff picks data using Axios
  const fetchStaffPicksData = async () => {
    try {
      const response = await axiosInstance.get(`${config.staffPicksUrl}?v=${randomValue}`);
      const data = response.data;
      const staffBooksArray = Object.keys(data).map((key) => ({
        isbn: data[key].in,
        title: data[key].te,
        sellingPrice: data[key].sp,
        lendingPricePerDay: data[key].lp,
        author: data[key].au,
        ageGroup: data[key].ag,
        genre: data[key].cy,
        condition: data[key].cn,
        imageUrl: `${config.imageUrlBase}${data[key].in}.jpg`,
      }));
      setStaffBooks(staffBooksArray);
    } catch (error) {
      console.error("Error fetching the staff picks data:", error);
      // 5xx errors handled by Axios interceptor
    }
  };

  // Fetch based on your view data using Axios
  const fetchBasedOnYourViewData = async () => {
    try {
      const response = await axiosInstance.get(`${config.basedOnYourViewUrl}?v=${randomValue}`);
      const data = response.data;
      const basedOnYourViewsBooksArray = Object.keys(data).map((key) => ({
        isbn: data[key].in,
        title: data[key].te,
        sellingPrice: data[key].sp,
        lendingPricePerDay: data[key].lp,
        author: data[key].au,
        ageGroup: data[key].ag,
        genre: data[key].cy,
        condition: data[key].cn,
        imageUrl: `${config.imageUrlBase}${data[key].in}.jpg`,
      }));
      setBasedOnYourViewBooks(basedOnYourViewsBooksArray);
    } catch (error) {
      console.error("Error fetching the based on your view data:", error);
      // 5xx errors handled by Axios interceptor
    }
  };

  // Use effect to fetch data on route change
  useEffect(() => {
    fetchBooksData();
    fetchStaffPicksData();
    fetchBasedOnYourViewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomValue]); // Refetch data when the randomValue changes (path changes)

  // Filter books based on age, genre, condition, and search term
  useEffect(() => {
    let updatedBooks = books;
    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedAges.includes(book.ageGroup)
      );
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedGenres.includes(book.genre)
      );
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedConditions.includes(book.condition)
      );
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(
        (book) =>
          (book.title &&
            book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (book.author &&
            book.author.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredBooks(updatedBooks);
  }, [selectedAges, selectedGenres, books, selectedConditions, searchTerm]);

  // If a 5xx error has occurred, redirect to the Error500 page
  if (error && error.type === "500") {
    return <Navigate to="/error500" replace />;
  }


  return (
    <>
      {axiosLoading && <Spinner />} {/* Show Spinner when Axios is loading */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              books={filteredBooks}
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              staffpicks={staffBooks}
              basedOnYourViews={basedOnYourViewBooks}
            />
          }
        />
        <Route
          path="/view-more/:book"
          element={
            <ViewMorePage
              books={filteredBooks}
              ageCategories={ageCategories}
              selectedAges={selectedAges}
              setSelectedAges={setSelectedAges}
              genres={genres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              selectedConditions={selectedConditions}
              setSelectedConditions={setSelectedConditions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              staffBooks={staffBooks}
              basedOnyourViews={basedOnYourViewBooks}
            />
          }
        />
        <Route
          path="/genre-based-books/:genre"
          element={
            <GenreBasedBook
              books={filteredBooks}
              ageCategories={ageCategories}
              selectedAges={selectedAges}
              setSelectedAges={setSelectedAges}
              genres={genres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              selectedConditions={selectedConditions}
              setSelectedConditions={setSelectedConditions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="/aboutus" element={<AboutUs genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>} />
        <Route path="/contact" element={<Contact  genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>} />
        <Route
          path="/cart"
          element={
            <Cart
              onSearch={setSearchTerm}
              searchTerm={searchTerm}
              books={filteredBooks}
            />
          }
        />

        <Route path="/terms_condtn" element={<TermsConditions genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>}/>
        <Route path="/refund_policy" element={<RefundPolicy genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}/>
        <Route path="/return_policy" element={<ReturnPolicy genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} />
        <Route path="/shipping_policy" element={<ShippingPolicy genres={genres} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>}/>        
        {/* Error Routes */}
        <Route path="/error500" element={<Error500 />} /> {/* Add Error500 Route */}
        {/* Wildcard route for 404 Not Found */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <ErrorProvider>
    <App />
    </ErrorProvider>
  </Router>
);

export default AppWithRouter;
