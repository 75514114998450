import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
  const savedState = JSON.parse(localStorage.getItem("borrowCartState"));
  return savedState || { items: [], Amount: 0 };
};

const BorrowCart = createSlice({
  name: "BorrowCart",
  initialState: initialState(),
  reducers: {
    add(state, action) {
      const { book, quantity } = action.payload;
      const existingBook = state.items.find((item) => item.isbn === book.isbn);
      if (existingBook) {
        existingBook.quantity += quantity;
        state.Amount += quantity * parseInt(existingBook.lendingPricePerDay, 10);
      } else {
        state.items.push({ ...book, quantity });
        state.Amount += quantity * parseInt(book.lendingPricePerDay, 10);
      }
      
      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
    remove(state, action) {
      const updatedItems = state.items.filter(
        (item) => item.isbn !== action.payload.isbn
      );
      const removedItem = state.items.find(
        (item) => item.isbn === action.payload.isbn
      );
      const newAmount =
        state.Amount - (removedItem.lendingPricePerDay * removedItem.quantity);
      state.items = updatedItems;
      state.Amount = newAmount;
      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
    increaseQuantity(state, action) {
      const book = state.items.find((item) => item.isbn === action.payload.isbn);
      if (book) {
        book.quantity += 1;
        state.Amount += parseInt(book.lendingPricePerDay, 10);
        localStorage.setItem("borrowCartState", JSON.stringify(state));
      }
    },
    decreaseQuantity(state, action) {
      const book = state.items.find((item) => item.isbn === action.payload.isbn);
      if (book && book.quantity > 1) {
        book.quantity -= 1;
        state.Amount -= parseInt(book.lendingPricePerDay, 10);
        localStorage.setItem("borrowCartState", JSON.stringify(state));
      }
    },
  },
});

export const { add, remove, increaseQuantity, decreaseQuantity } = BorrowCart.actions;
export const Borrowreducers = BorrowCart.reducer;
