import React, { Fragment } from "react";
import HeaderComponent from "../Components/HeaderComponent";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from '../assets/Footer';
import { useNavigate } from 'react-router-dom';

const ReturnPolicy = ({ searchTerm, setSearchTerm, genres }) => {
    const navigate = useNavigate();

    const handleSearch = (term) => {
        setSearchTerm(term);
        navigate('/view-more/book');
    };

    return (
        <Fragment>
            <HeaderComponent searchTerm={searchTerm} handleSearch={handleSearch} genres={genres} />
            <div style={{ marginTop: "180px" }}></div>
            <Breadcrumb
                pages={[
                    { label: "Home", path: "/" },
                    { label: "Return Policy", path: "/return-policy" }, // Adjust path if needed
                ]}
            />
            <div className="container my-5">
                <h2>Return Policy</h2>
                <p>
                    To be eligible for a return, we need an opening/unboxing videos of the parcel from start to end without any pauses and Email or WhatsApp us your full video for any claim within 24 hours after product delivery. Kindly note we sell both new and preloved books on this site, so before adding to the cart, please check the condition mentioned.
                </p>
                <p>You can claim a refund/return for the product for the following cases only:</p>
                <ul>
                    <li>The item may be damaged during transit.</li>
                    <li>Wrong item is delivered to you.</li>
                </ul>
                <h4>Return of a Product:</h4>
                <p>
                    For returns of the product, you must pack the items in the same condition and courier them to our address. We will reimburse the courier amount to you.
                </p>
                <h4>Replacements and Refunds:</h4>
                <p>
                    We will notify you once we received and inspected your returned item in the same condition as we sent it and let you know if the refund was approved or not. If approved, for refunds, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too. For replacements, we will replace the item at no extra cost if the product is available with us in stock; otherwise, we will refund the amount.
                </p>
            </div>
            <div style={{ marginTop: "200px" }}>
                <Footer />
            </div>
        </Fragment>
    );
};

export default ReturnPolicy;
