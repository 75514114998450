import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'; 
import { Navigation, Autoplay } from 'swiper/modules';

export default function SwiperComponent() {
  return (
    <div 
      style={{ 
        width: '100%', 
        height: '100%', 
        position: 'relative' 
      }}
    >
      <Swiper
        navigation={true} 
        modules={[Navigation, Autoplay]} 
        autoplay={{ delay: 3000, disableOnInteraction: false }} 
        style={{ 
          width: '100%', 
          height: '100%' 
        }}
        slidesPerView={1}
        loop={true}
      >
        <SwiperSlide 
          style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundImage: `url('/jingus_banner1.jpeg')`, 
            backgroundSize: 'cover', // Default for larger screens
            backgroundPosition: 'center',
            height: '100%',
            '@media (max-width: 768px)': { // Tablet and smaller screens
              backgroundSize: 'contain',
            },
            '@media (max-width: 480px)': { // Mobile screens
              backgroundSize: 'cover',
              backgroundPosition: 'top',
            }
          }}
        />
        <SwiperSlide 
          style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundImage: `url('/jingus_banner2.jpeg')`, 
            backgroundSize: 'cover', // Default for larger screens
            backgroundPosition: 'center',
            height: '100%',
            '@media (max-width: 768px)': { // Tablet and smaller screens
              backgroundSize: 'contain',
            },
            '@media (max-width: 480px)': { // Mobile screens
              backgroundSize: 'cover',
              backgroundPosition: 'top',
            }
          }}
        />
        {/* Other slides */}
      </Swiper>
    </div>
  );
}
