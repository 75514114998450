// src/components/Contact.jsx
import React, { Fragment } from 'react';
import HeaderComponent from '../Components/HeaderComponent';
import Footer from './Footer';
import Breadcrumb from "../wrapper/Breadcrumb"; 

import { useNavigate } from 'react-router-dom';
const Contact = ({ searchTerm, setSearchTerm, genres }) => {
  const navigate = useNavigate();
  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate('/view-more/book');
};
  return (
     <Fragment>
        <HeaderComponent  searchTerm={searchTerm} handleSearch={handleSearch} genres={genres}/>
        <div style={{ marginTop: "210px" }}></div>
        <Breadcrumb
                pages={[
                    { label: "Home", path: "/" },
                    { label: "ContactUs", path: "/contactt" }, // Adjust path if needed
                ]}
            />
            
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Contact Us</h1>
      <p>
        For any questions or feedback, please reach us at:
      </p>
      <a href="mailto:support@jingus.in">support@jingus.in</a>
    </div>
    <div style={{marginTop:"300px"}}>
        <Footer />
    </div>
    </Fragment>
  );
}

export default Contact;
