import React from "react";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import { useNavigate } from 'react-router-dom';
import HeaderComponent from "../Components/HeaderComponent";

const PrivacyPolicy = ({ searchTerm, setSearchTerm, genres }) => {
  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate('/view-more/book');
  };

  return (
    <>
      <HeaderComponent searchTerm={searchTerm} handleSearch={handleSearch} genres={genres} /> 
      <div style={{ marginTop: "180px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Privacy Policy", path: "/privacy-policy" },
        ]}
      />
      <div className="container my-5">
        <h1 className="mb-4">Privacy Policy</h1>

        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how JINGUS SYSTEMS and its affiliates
          (collectively "JINGUS SYSTEMS, we, our, us") collect, use, share,
          protect, or otherwise process your information/personal data through
          our website jingus.in (hereinafter referred to as Platform). Please
          note that you may be able to browse certain sections of the Platform
          without registering with us. We do not offer any product/service under
          this Platform outside India, and your personal data will primarily be
          stored and processed in India.
        </p>
        <p>
          By visiting this Platform, providing your information, or availing any
          product/service offered on the Platform, you expressly agree to be
          bound by the terms and conditions of this Privacy Policy, the Terms of
          Use, and the applicable service/product terms and conditions, and
          agree to be governed by the laws of India, including but not limited
          to the laws applicable to data protection and privacy. If you do not
          agree, please do not use or access our Platform.
        </p>

        <h2>Collection</h2>
        <p>
          We collect your personal data when you use our Platform, services, or
          otherwise interact with us during the course of our relationship. Some
          of the information that we may collect includes, but is not limited
          to, personal data provided to us during sign-up/registering or using
          our Platform, such as name, date of birth, address, telephone/mobile
          number, email ID, and any such information shared as proof of identity
          or address. Some sensitive personal data may be collected with your
          consent, such as your bank account, credit or debit card information,
          or biometric data in accordance with applicable law(s).
        </p>
        <p>
          We may track your behavior, preferences, and other information that
          you choose to provide on our Platform. This information is compiled
          and analyzed on an aggregated basis. We also collect your information
          related to your transactions on the Platform and such third-party
          business partner platforms. When a third-party business partner
          collects your personal data directly from you, you will be governed by
          their privacy policies.
        </p>
        <p>
          If you receive an email or call from a person/association claiming to
          be JINGUS SYSTEMS seeking personal data such as debit/credit card PIN,
          net-banking, or mobile banking password, we request you to never
          provide such information.
        </p>

        <h2>Usage</h2>
        <p>
          We use personal data to provide the services you request. To the
          extent we use your personal data to market to you, we will provide you
          the ability to opt-out of such uses. We use your personal data to
          assist sellers and business partners in handling and fulfilling
          orders, enhancing customer experience, resolving disputes,
          troubleshooting problems, customizing your experience, detecting and
          protecting us against error, fraud, and other criminal activity,
          enforcing our terms and conditions, and conducting marketing research
          and surveys.
        </p>

        <h2>Sharing</h2>
        <p>
          We may share your personal data internally within our group entities,
          our other corporate entities, and affiliates to provide you access to
          services and products offered by them. These entities and affiliates
          may market to you as a result of such sharing unless you explicitly
          opt-out. We may disclose personal data to third parties, including
          sellers, business partners, and third-party service providers, to
          provide you access to our services, fulfill legal obligations, and
          prevent fraud.
        </p>
        <p>
          We may disclose personal data to law enforcement or government
          agencies when required by law or in good faith for the purposes of
          protecting the rights of our users or the public.
        </p>

        <h2>Security Precautions</h2>
        <p>
          To protect your personal data from unauthorized access, disclosure, or
          misuse, we adopt reasonable security practices and procedures.
          However, data transmission over the internet is not completely secure,
          and we cannot guarantee complete security.
        </p>

        <h2>Data Deletion and Retention</h2>
        <p>
          You have the option to delete your account by visiting your profile
          and settings on our Platform. This action will result in losing all
          information related to your account. We may retain your personal data
          for a period as required under applicable law, or for other legitimate
          purposes.
        </p>

        <h2>Your Rights</h2>
        <p>
          You may access, rectify, and update your personal data directly
          through the functionalities provided on the Platform.
        </p>

        <h2>Consent</h2>
        <p>
          By visiting our Platform or providing your information, you consent to
          the collection, use, storage, disclosure, and processing of your
          information as described in this Privacy Policy. You have the option
          to withdraw your consent by contacting us, but doing so may affect
          your access to services.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy to reflect changes in our practices.
          Please review it periodically for changes.
        </p>

        <h2>Grievance Officer</h2>
        <p>
          Sowmya S<br />
          Jingus Systems,<br />
          94 MyHome Ankura,<br />
          Tellapur, Hyderabad 502032<br />
          Contact Us: support@jingus.in<br />
          Phone: 78426 31444<br />
          Time: Monday - Friday (9:00 - 18:00)
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
