import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import '../css/Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        {/* Main Footer Links */}
        <Row className="footer-container custom-row">
          <Col xs={12} md={3} className="footer-column" style={{ fontFamily: "Quicksand, sans-serif" }}>
            <img 
              src="jingus-logo1.png" 
              alt="Footer Logo" 
              className="footer-logo" 
            />
          </Col>
          <Col xs={12} md={3} className="footer-column">
            <div className="footer-section">
              <div className="footer-heading">ABOUT US</div>
              <ul className="footer-list">
                <li><Link to="/aboutus">About us</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3} className="footer-column">
            <div className="footer-section">
              <div className="footer-heading">FOLLOW US</div>
              <ul className="footer-list">
                <li><a href="https://www.instagram.com/jingus_books?utm_source=qr&igsh=M2JqOGx4YmM5dzh2" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="footer-container custom-row">
          <Col xs={12} md={8} className="footer-column">
            <ul className="list-inline mb-0">
              <li className="list-inline-item mx-2">
                <a href="/terms_condtn" className="text-muted">Terms&Conditions</a>
              </li>             
              <li className="list-inline-item mx-2">
                <a href="/privacy_policy" className="text-muted">PrivacyPolicy</a>
              </li>
              <li className="list-inline-item mx-2">
                <a href="/refund_policy" className="text-muted">RefundPolicy</a>
              </li>
              <li className="list-inline-item mx-2">
                <a href="/return_policy" className="text-muted">ReturnPolicy</a>
              </li>
              <li className="list-inline-item mx-2">
                <a href="/shipping_policy" className="text-muted">ShippingPolicy</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
