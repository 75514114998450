import React, { Fragment } from "react";
import HeaderComponent from "../Components/HeaderComponent"; 
import Footer from "../assets/Footer";
import Breadcrumb from "../wrapper/Breadcrumb";
import { useNavigate } from 'react-router-dom';

const ShippingPolicy = ({ searchTerm, setSearchTerm, genres }) => {
  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate('/view-more/book');
  };

  return (
    <Fragment>
     <HeaderComponent searchTerm={searchTerm} handleSearch={handleSearch} genres={genres} /> {/* Use HeaderComponent */}   
     <div style={{ marginTop: "180px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Shipping Policy", path: "/shipping-policy" },
        ]}
      />
      
      <div className="container my-5">
        <h1>Shipping Policy</h1>
        <p>
          Orders for the user are shipped through registered domestic courier
          companies and/or speed post only. Orders are shipped within 5 days
          from the date of order and/or payment or as per the delivery date
          agreed at the time of order confirmation, subject to courier company
          or post office norms.
        </p>
        
        <p>
          The Platform Owner shall not be liable for any delay in delivery by
          the courier company or postal authority. All orders will be delivered
          to the address provided by the buyer at the time of purchase.
        </p>

        <p>
          Delivery of services will be confirmed via your email address as
          specified during registration. If there are any shipping costs levied
          by the seller or the Platform Owner, these costs are non-refundable.
        </p>
      </div>
      <div style={{marginTop:"200px"}}>
      <Footer />
      </div>
    </Fragment>
  );
};

export default ShippingPolicy;
