// src/Context/ErrorContext.jsx
import React, { createContext, useState } from "react";

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null); // { type: '500', message: '...' }

  const value = {
    error,
    setError,
  };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};
