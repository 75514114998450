
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ErrorContext } from "../Context/ErrorContext";
import "../css/Error500.css"

const Error500 = () => {
  const { setError } = useContext(ErrorContext);

  const handleGoHome = () => {
    setError(null); // Reset the error state
  };

  return (
    <div className="error-page">
      <h1>500 - Internal Server Error</h1>
      <p>Oops! Something went wrong on our end.</p>
      <Link to="/" onClick={handleGoHome}>
        Go Back Home
      </Link>
    </div>
  );
};

export default Error500;
