import React, { useState, useEffect } from 'react';
import DisplayBooksGrid from './DisplayBooksGrid';
import { useParams, useNavigate } from 'react-router-dom';
import SidebarMenuTwo from '../Header/SideBarMenuTwo';
import Header from '../Header/Header';
import SearchBar from './SearchBar';

const GenreBasedBook = ({
  books,
  ageCategories,
  selectedAges,
  setSelectedAges,
  genres,
  selectedGenres,
  setSelectedGenres,
  selectedConditions,
  setSelectedConditions,
  searchTerm,
  setSearchTerm,
  loading,
}) => {
  const { genre } = useParams(); 
  const [filteredBooks, setFilteredBooks] = useState([]);
  const navigate = useNavigate();

  // Automatically select the genre from the URL in the filter
  useEffect(() => {
    if (genre && !selectedGenres.includes(genre)) {
      setSelectedGenres([genre]);
    }
  }, [genre, selectedGenres, setSelectedGenres]);

  useEffect(() => {
    let updatedBooks = books;

    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedAges.includes(book.ageGroup));
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedGenres.includes(book.genre));
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedConditions.includes(book.condition));
    }

    setFilteredBooks(updatedBooks);
  }, [books, selectedAges, selectedGenres, selectedConditions]);

  // Handle search and navigate to "view-more/book" when search is activated
  const handleSearch = (term) => {
    setSearchTerm(term); // Update the search term state
    setSelectedGenres([])
    if (term.length > 1) {
      navigate("/view-more/book");
    }
  };

  return (
    <div>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={handleSearch} /> {/* Search bar passes handleSearch */}
      <div className='menu-bar'>
        <SidebarMenuTwo
          genres={genres}
          ageCategories={ageCategories}
          selectedAges={selectedAges}
          onAgeSelect={setSelectedAges}
          selectedGenres={selectedGenres}
          onGenreSelect={setSelectedGenres}
          selectedConditions={selectedConditions}
          onConditionSelect={setSelectedConditions}
        />
      </div>
      <div style={{ marginTop: '155px', padding: '20px', width: '100%', height: '350px' }}>
        <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', overflowY: 'auto' }}>
          {loading ? (
            <p>Loading books...</p>
          ) : (
            <DisplayBooksGrid books={filteredBooks} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GenreBasedBook;
