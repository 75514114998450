const BookInfo = ({  author }) => (
  <div style={{ textAlign: 'center', marginBottom: '8px', color: '#333' }}>
   <p style={{
      fontSize: '0.9rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
            }}> 
         {author}
        </p>
  </div>
);

export default BookInfo;
