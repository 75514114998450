import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
  const savedState = JSON.parse(localStorage.getItem("buyCartState"));
  return savedState || { items: [], Amount: 0 };
};

const BuyCart = createSlice({
  name: "BuyCart",
  initialState: initialState(),
  reducers: {
    addBuy(state, action) {
      const { book, quantity } = action.payload;
      const existingBook = state.items.find((item) => item.isbn === book.isbn);
      if (existingBook) {
        existingBook.quantity += quantity;
        state.Amount += quantity * parseInt(existingBook.sellingPrice, 10);
      } else {
        state.items.push({ ...book, quantity });
        state.Amount += quantity * parseInt(book.sellingPrice, 10);
      }
      localStorage.setItem("buyCartState", JSON.stringify(state));
    },
    buyRemove(state, action) {
      const updatedItems = state.items.filter(
        (item) => item.isbn !== action.payload.isbn
      );
      const removedItem = state.items.find(
        (item) => item.isbn === action.payload.isbn
      );
      const newAmount =
        state.Amount - (parseInt(removedItem.sellingPrice, 10) * removedItem.quantity);
      state.items = updatedItems;
      state.Amount = newAmount;
      localStorage.setItem("buyCartState", JSON.stringify(state));
    },
    buyIncreaseQuantity(state, action) {
      const book = state.items.find((item) => item.isbn === action.payload.isbn);
      if (book) {
        book.quantity += 1;
        state.Amount += parseInt(book.sellingPrice, 10);
        localStorage.setItem("buyCartState", JSON.stringify(state));
      }
    },
    buyDecreaseQuantity(state, action) {
      const book = state.items.find((item) => item.isbn === action.payload.isbn);
      if (book && book.quantity > 1) {
        book.quantity -= 1;
        state.Amount -= parseInt(book.sellingPrice, 10);
        localStorage.setItem("buyCartState", JSON.stringify(state));
      }
    },
  },
});

export const { addBuy, buyRemove, buyIncreaseQuantity, buyDecreaseQuantity } = BuyCart.actions;
export const BuyReducer = BuyCart.reducer;
