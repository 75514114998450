import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const SideBarMenuOne = ({ genres }) => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "#3c3c3c" }}>
      <Container fluid style={{ backgroundColor: "#3c3c3c", padding: 0 }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: "white" ,marginBottom:"8px"}} />
        <Navbar.Collapse id="basic-navbar-nav" style={{ backgroundColor: "#3c3c3c"}}>
          <Nav className="me-auto" style={{ backgroundColor: "#3c3c3c", width: "100%" }}>
            <Nav.Item style={{marginLeft:"200px"}}></Nav.Item>
            <Nav.Item style={{marginRight:"150px",}}>
              <Link to={`/view-more/book`} style={{color:"#fff",textDecoration:"none"}}>AllBooks</Link>
            </Nav.Item>
            {genres.map((genre) => (
              <Nav.Item key={genre} style={{ marginRight: "150px"}}>
                <Link to={`/genre-based-books/${genre}`} style={{color:"#fff",textDecoration:"none"}}>{genre}</Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SideBarMenuOne;