import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import BookInfo from './BookInfo';
import Price from './Price';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect

const DisplayBook = ({ book }) => {

  const [isImgError, setIsImgError] = useState(false); // State to track image error

  // Handle image load error by setting a fallback
  const handleError = () => {
    setIsImgError(true); // Set error state
  };

  return (
    <Card
      style={{
        width: '100%',
        maxWidth: '14rem',
        margin: '10px',
        border: 'none',
        transition: '0.3s',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '150px',
          height: '200px',
          margin: '0 auto',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isImgError ? '#f0f0f0' : 'transparent', // Light background for text fallback
        }}
      >
        {!isImgError ? (
          <LazyLoadImage
            src={book.imageUrl}
            alt={book.title}
            effect="blur" 
            onError={handleError} 
            style={{
              width: '150px',  
              height: '200px',
              objectFit: 'cover',
              position: 'relative',
            }}
          />
        ) : (
          <span
            style={{
              fontSize: '1rem',
              textAlign: 'center',
              padding: '10px',
              color: '#333',
            }}
          >
            {book.title}
          </span>
        )}
        <Badge
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            backgroundColor: '#e74c3c',
            fontSize: '0.7rem',
            padding: '5px',
            zIndex: 1,
          }}
        >
          {book.condition}
        </Badge>
      </div>

      <Card.Body
        style={{
          padding: '10px',
          textAlign: 'center',
        }}
      >
        <Card.Title
          style={{
            fontSize: '0.9rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {book.title}
        </Card.Title>
        <BookInfo author={book.author} />
        <Price
          lendingPricePerDay={book.lendingPricePerDay}
          sellingPrice={book.sellingPrice}
          book={book}
        />
      </Card.Body>
    </Card>
  );
};



export default DisplayBook;
