import React from 'react';
import { Link } from 'react-router-dom';
import SwiperBookComponents from './SwiperBookComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import '../css/Staffpicks.css'; // Import the CSS file

const Staffpicks = ({ books }) => {


  return (
    <div className="staffpicks-container">
      <div className="staffpicks-header">
        <span>Staffpicks</span>
        <Link to={"/view-more/staff"} className="staffpicks-link">
          View More <FontAwesomeIcon icon={faCaretRight} />
        </Link>
      </div>
      <div className="staffpicks-books">
          <SwiperBookComponents books={books} id="swiper2" />
        
      </div>
    </div>
  );
};

export default Staffpicks;
