import React from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
const Genre = ({ selectedGenres, onSelect, genres, toggleSidebar }) => {
  const { genre: genreFromUrl } = useParams(); 

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onSelect(prev => [...prev, value]);
    } else {
      onSelect(prev => prev.filter(genre => genre !== value));
    }
    if (toggleSidebar) {
      toggleSidebar(); 
    }
  };

  return (
    <Form>
      {genres.map(genre => (
        <Form.Check
          key={genre}
          type="checkbox"
          id={`genre-${genre}`}
          value={genre}
          checked={selectedGenres.includes(genre)}
          onChange={handleChange}
          label={genre}
          disabled={genreFromUrl && genreFromUrl !== genre} 
        />
      ))}
    </Form>
  );
};

export default Genre;
