import { configureStore } from "@reduxjs/toolkit";
import { Borrowreducers } from "./BorrowCart";
import { BuyReducer } from "./BuyCart";

export const Store=configureStore({
    reducer:{
        BorrowCart:Borrowreducers,
        BuyCart:BuyReducer,
    }
});
