import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  const phoneNumber = '+917842631444'; // Replace with your WhatsApp number

  return (
    <a 
      href={`https://wa.me/${phoneNumber}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{
        color: "white", 
        display: 'flex', 
        alignItems: 'center'
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '20px' }} />
      <span style={{ marginLeft: '10px' }}>+91 78426 31444</span>
    </a>
  );
};

export default WhatsAppButton;
