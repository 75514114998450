import React from 'react';
import "../styles.css";
import { Stack } from "react-bootstrap";
import HeaderComponent from './HeaderComponent'; // Updated import
import SwiperComponent from './SwiperComponent';
import 'swiper/swiper-bundle.css';
import Staffpicks from './StaffPicks';
import GenreComponents from './GenreComponents';
import BasedOnYourView from './BasedOnYourView';
import Footer from '../assets/Footer';
import { useNavigate } from 'react-router-dom';

const Home = ({
  books,
  genres,
  searchTerm,
  staffpicks,
  setSearchTerm,
  basedOnYourViews
}) => {
  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate('/view-more/book');
  };

  return (
    <Stack>
      <HeaderComponent 
        searchTerm={searchTerm} 
        handleSearch={handleSearch} 
        genres={genres} 
      /> {/* Using HeaderComponent with props */}

      <div style={{ marginTop: '155px', backgroundColor: '#f0f0f0', width: '100%', height: '600px' }} className="swiper-container">
        <SwiperComponent />
      </div>

      <div>
        <GenreComponents books={books} genres={genres} />
      </div>
      <div>
        <Staffpicks books={staffpicks} />
      </div>
      <div>
        <BasedOnYourView books={basedOnYourViews} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Footer />
      </div>
    </Stack>
  );
};

export default Home;
