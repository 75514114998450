// Spinner.js
import React from 'react';
import "../css/Spinner.css"

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="loading"></div>
    </div>
  );
};

export default Spinner;
