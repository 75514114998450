import DisplayBook from "./DisplayBook";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

const SwiperBookComponents = ({ books, id }) => {
  const customNavStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    color: 'blue',
    padding: '15px',
    borderRadius: '50%',
    cursor: 'pointer', 
  };

  return (
    <div className="swiper-container" style={{ position: 'relative', width: '100%', padding: "0 10vw" }}>
      <Swiper
        navigation={{
          prevEl: `.${id}-swiper-button-prev`,
          nextEl: `.${id}-swiper-button-next`,
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
        style={{ width: '100%' }}
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          800:{
            slidesPerView:3,
            spaceBetween:10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1300:{
            slidesPerView:4,
            spaceBetween:10,
          },
          1800:{
            slidesPerView:6,
            spaceBetween:20
          }
        }}
      >
         {/* style={{ width: 'calc(100% / 6)' }} */}
        {books.map((book, index) => (
          <SwiperSlide key={index} style={{ width: 'calc(100% / 6)' }}>
            <DisplayBook book={book} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom navigation buttons */}
      <div
        className={`${id}-swiper-button-prev custom-prev`}
        style={{ ...customNavStyle, left: '10px' }} // Added some padding from the left
      >
        &#10094; {/* Left Arrow */}
      </div>
      <div
        className={`${id}-swiper-button-next custom-next`}
        style={{ ...customNavStyle, right: '10px' }} // Added some padding from the right
      >
        &#10095; {/* Right Arrow */}
      </div>
    </div>
  );
};

export default SwiperBookComponents;
