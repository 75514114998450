import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import AgeCategory from './AgeCategory';
import Conditions from './Conditions';
import Genre from './Genre';

const FilterComponent = ({
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  selectedConditions,
  onConditionSelect,
  ageCategories,
  genres,
  toggleSidebar, 
}) => {
  return (
    <div>
      <NavDropdown.Item>
        <span><strong>Age Categories:</strong></span>
        <AgeCategory
          selectedAges={selectedAges}
          onSelect={onAgeSelect}
          ageCategories={ageCategories}
          toggleSidebar={toggleSidebar} 
        />
      </NavDropdown.Item>
      <NavDropdown.Item>
        <span><strong>Genres:</strong></span>
        <Genre
          genres={genres}
          selectedGenres={selectedGenres}
          onSelect={onGenreSelect}
          toggleSidebar={toggleSidebar} 
        />
      </NavDropdown.Item>
      <NavDropdown.Item>
        <span><strong>Conditions:</strong></span>
        <Conditions
          selectedConditions={selectedConditions}
          onSelect={onConditionSelect}
          toggleSidebar={toggleSidebar} 
        />
      </NavDropdown.Item>
    </div>
  );
};

export default FilterComponent;
