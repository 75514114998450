// src/axiosInstance.js
import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create();

// Function to set up Axios interceptors
export const setupAxiosInterceptors = (setAxiosLoading, setError) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      setAxiosLoading(true);
      return config;
    },
    (error) => {
      setAxiosLoading(false);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      setAxiosLoading(false);
      return response;
    },
    (error) => {
      setAxiosLoading(false);
      if (error.response) {
        const status = error.response.status;
        if (status >= 500 && status < 600) {
          setError({ type: "500", message: "Internal Server Error" });
        } 
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
