
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ErrorContext } from "../Context/ErrorContext";
import "../css/Error404.css"

const Error404 = () => {
  const { setError } = useContext(ErrorContext);

  const handleGoHome = () => {
    setError(null); 
  };

  return (
    <div className="error-page">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for doesn't exist.</p>
      <Link to="/" onClick={handleGoHome}>
        Go Back Home
      </Link>
    </div>
  );
};

export default Error404;
