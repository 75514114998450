import React from 'react';
import { Link } from 'react-router-dom';
import SwiperBookComponents from './SwiperBookComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import "../css/BasedOnYourView.css"; // Import the CSS file

const BasedOnYourView = ({ books }) => {


  return (
    <div className="based-on-your-view-container">
      <div className="based-on-your-view-header">
        <span>Based On Your View</span>
        <Link to={"/view-more/based"} className="based-on-your-view-link">
          View More <FontAwesomeIcon icon={faCaretRight} />
        </Link>
      </div>
      <div className="based-on-your-view-books">
          <SwiperBookComponents books={books} id="swiper3" />
      </div>
    </div>
  );
};

export default BasedOnYourView;
